.size{
    
    background-color: #161b21;
}
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oxygen&display=swap');

.title{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
    font-family:'Lato', sans-serif; 
    color:white;
}
.info{
    margin:25px;
}

.textBlue{
    font-family:'Oxygen', sans-serif;
    color:black;
    font-size:24px;
    text-transform: uppercase; 
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
    margin-top:50px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .cardSize{
    
    width: 400px !important;
  }

  .iconStyle{
    display: inline-flex;
    vertical-align: top;
  }
  .flexRow{
    display:flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    flex-direction:row;
  }
  .flexStart{
    display:flex;
    justify-content:flex-start;
    align-items: center;
    text-align: center;
    flex-direction:row;
  }
  .particles2 {
    position: absolute !important;
    width:100%;
    height:100%;
   
  }
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
  .source{
    font-family: 'Source Sans Pro', sans-serif;

  }