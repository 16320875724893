@import url(https://fonts.googleapis.com/css?family=Oxygen&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oxygen&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oxygen&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
}
.flexCenter{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
  }
  
  .oxygen{
    font-family: 'Oxygen', sans-serif;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
}
#Landing{
    /* background-image:url('./pics/background5.jpg'); */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
    padding:0 20px;
    z-index: -1;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-color:#1D1B1B;
    
  }

  #Landing h1{
    font-size:100px;
    /* line-height:1.2; */
    font-family:'Lato', sans-serif;
    color:#EC4D37;
  }
  #Landing p{
    font-size:35px;
    font-family:'Lato', sans-serif;
    position: relative;
  }

  .flexCenter{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
  }
  .particles {
    position: absolute !important;
    left:0;
    top:0;
    width:100%;
    height:100%;
  }

  /* Media */
  @media screen and (min-width: 200px){
    html{
      font-size: 10px;
    }
    #experience, #projects, .skills,#contact{
      width: 100% !important;
    }
  }
  @media screen and (min-width: 320px){
    html{
      font-size: 12px;
    }
    #experience, #projects, .skills,.photos,#contact{
      width: 100% !important;
    }
  
  }
  @media screen and (min-width: 400px){
    html{
      font-size: 13px;
    }
    #experience, #projects, .skills,.photos,#contact,.profile{
      width: 100% !important;
    }
    
  }
  @media screen and (min-width: 500px){
      html{
        font-size: 13px;
      }
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
      }
      
    }
    @media screen and (min-width: 580px){
      html{
        font-size: 13px;
      }
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      }
     
    }
    @media screen and (min-width: 620px){
      html{
        font-size: 15px;
      }
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 680px){
      html{
        font-size: 15px;
      }
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 720px){
      html{
        font-size: 15px;
      }
      
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 800px){
     
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
        
      }
      
      
    }
    @media screen and (min-width: 860px){
     
      .experience, .projects, .skills,.photos,.contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 920px){
      
      .experience, .projects, .skills,.photos,.contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 1000px){
      
      .experience, .projects, .skills,.photos,.contact{
        width: 100% !important;
      }
    }
  
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {
      html{
        font-size: 14px;
      }
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      }
    }
  
    @media screen and (orientation: portrait) {
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      }
    }
    @media screen and (orientation: landscape) {
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      }
    }

    @media (min-width: 1281px) {
  
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
    
    /* 
      ##Device = Laptops, Desktops
      ##Screen = B/w 1025px to 1280px
    */
    
    @media (min-width: 1025px) and (max-width: 1280px) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    
    /* 
      ##Device = Tablets, Ipads (portrait)
      ##Screen = B/w 768px to 1024px
    */
    
    @media (min-width: 768px) and (max-width: 1024px) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    
    /* 
      ##Device = Tablets, Ipads (landscape)
      ##Screen = B/w 768px to 1024px
    */
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    
    /* 
      ##Device = Low Resolution Tablets, Mobiles (Landscape)
      ##Screen = B/w 481px to 767px
    */
    
    @media (min-width: 481px) and (max-width: 767px) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    
    /* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */
    
    @media (min-width: 320px) and (max-width: 480px) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    }
.size{
    
    background-color: #161b21;
}

.title{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
    font-family:'Lato', sans-serif; 
    color:white;
}
.info{
    margin:25px;
}

.textBlue{
    font-family:'Oxygen', sans-serif;
    color:black;
    font-size:24px;
    text-transform: uppercase; 
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
    margin-top:50px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
  }

  .cardSize{
    
    width: 400px !important;
  }

  .iconStyle{
    display: inline-flex;
    vertical-align: top;
  }
  .flexRow{
    display:flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    flex-direction:row;
  }
  .flexStart{
    display:flex;
    justify-content:flex-start;
    align-items: center;
    text-align: center;
    flex-direction:row;
  }
  .particles2 {
    position: absolute !important;
    width:100%;
    height:100%;
   
  }
  .source{
    font-family: 'Source Sans Pro', sans-serif;

  }


.title{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
    font-family:'Lato', sans-serif; 
    color:white;
}

.flexRow{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:row;
  }
  .projects {
    border: 1px solid black;
    position: relative;
    display: inline-block;
}

.projects:hover {
    box-shadow: 4px 3px rgba(107, 107, 107, .35);
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(255, 255, 255, .87);
}

.text {
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    width:300px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-family: 'Roboto', sans-serif;
    font-size:15px;
}

.projects:hover .overlay {
    opacity: 1;
}
img{
    max-width: 100%;
    width:100%; 
    
}
.image{
    max-width: 100%;
    width:100%; 
    vertical-align: middle;
    border-style: none;
    border: 0;
}
.particles2 {
    position: absolute !important;
    width:100%;
    height:100%;
   
  }


  .flexCenter{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
  }
 
.oxygen{
    font-family: 'Oxygen', sans-serif;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
}

/* .backColor{
 background: #C9CCD3;
 background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
 background-blend-mode: lighten;
} */
