#Landing{
    /* background-image:url('./pics/background5.jpg'); */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
    padding:0 20px;
    z-index: -1;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-color:#1D1B1B;
    
  }

  @import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

  #Landing h1{
    font-size:100px;
    /* line-height:1.2; */
    font-family:'Lato', sans-serif;
    color:#EC4D37;
  }
  #Landing p{
    font-size:35px;
    font-family:'Lato', sans-serif;
    position: relative;
  }

  .flexCenter{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
  }
  .particles {
    position: absolute !important;
    left:0;
    top:0;
    width:100%;
    height:100%;
  }

  /* Media */
  @media screen and (min-width: 200px){
    html{
      font-size: 10px;
    }
    #experience, #projects, .skills,#contact{
      width: 100% !important;
    }
  }
  @media screen and (min-width: 320px){
    html{
      font-size: 12px;
    }
    #experience, #projects, .skills,.photos,#contact{
      width: 100% !important;
    }
  
  }
  @media screen and (min-width: 400px){
    html{
      font-size: 13px;
    }
    #experience, #projects, .skills,.photos,#contact,.profile{
      width: 100% !important;
    }
    
  }
  @media screen and (min-width: 500px){
      html{
        font-size: 13px;
      }
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
      }
      
    }
    @media screen and (min-width: 580px){
      html{
        font-size: 13px;
      }
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      }
     
    }
    @media screen and (min-width: 620px){
      html{
        font-size: 15px;
      }
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 680px){
      html{
        font-size: 15px;
      }
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 720px){
      html{
        font-size: 15px;
      }
      
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 800px){
     
      #experience, #projects, .skills,.photos,#contact{
        width: 100% !important;
        
      }
      
      
    }
    @media screen and (min-width: 860px){
     
      .experience, .projects, .skills,.photos,.contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 920px){
      
      .experience, .projects, .skills,.photos,.contact{
        width: 100% !important;
      }
    }
    @media screen and (min-width: 1000px){
      
      .experience, .projects, .skills,.photos,.contact{
        width: 100% !important;
      }
    }
  
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {
      html{
        font-size: 14px;
      }
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      }
    }
  
    @media screen and (orientation: portrait) {
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      }
    }
    @media screen and (orientation: landscape) {
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      }
    }

    @media (min-width: 1281px) {
  
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
    
    /* 
      ##Device = Laptops, Desktops
      ##Screen = B/w 1025px to 1280px
    */
    
    @media (min-width: 1025px) and (max-width: 1280px) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    
    /* 
      ##Device = Tablets, Ipads (portrait)
      ##Screen = B/w 768px to 1024px
    */
    
    @media (min-width: 768px) and (max-width: 1024px) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    
    /* 
      ##Device = Tablets, Ipads (landscape)
      ##Screen = B/w 768px to 1024px
    */
    
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    
    /* 
      ##Device = Low Resolution Tablets, Mobiles (Landscape)
      ##Screen = B/w 481px to 767px
    */
    
    @media (min-width: 481px) and (max-width: 767px) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    
    /* 
      ##Device = Most of the Smartphones Mobiles (Portrait)
      ##Screen = B/w 320px to 479px
    */
    
    @media (min-width: 320px) and (max-width: 480px) {
      
      #experience, #projects, .skills,.photos,#contact,.profile{
        width: 100% !important;
      
    }
  }
    }