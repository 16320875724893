
  .flexCenter{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
  }
 
.oxygen{
    font-family: 'Oxygen', sans-serif;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
}
@import url('https://fonts.googleapis.com/css?family=Oxygen&display=swap');

/* .backColor{
 background: #C9CCD3;
 background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
 background-blend-mode: lighten;
} */