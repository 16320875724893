

.title{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
    font-family:'Lato', sans-serif; 
    color:white;
}

.flexRow{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:row;
  }
  .projects {
    border: 1px solid black;
    position: relative;
    display: inline-block;
}

.projects:hover {
    box-shadow: 4px 3px rgba(107, 107, 107, .35);
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(255, 255, 255, .87);
}

.text {
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    width:300px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-family: 'Roboto', sans-serif;
    font-size:15px;
}

.projects:hover .overlay {
    opacity: 1;
}
img{
    max-width: 100%;
    width:100%; 
    
}
.image{
    max-width: 100%;
    width:100%; 
    vertical-align: middle;
    border-style: none;
    border: 0;
}
.particles2 {
    position: absolute !important;
    width:100%;
    height:100%;
   
  }