* {
  margin: 0;
  padding: 0;
}
.flexCenter{
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
  }

  @import url('https://fonts.googleapis.com/css?family=Oxygen&display=swap');
  
  .oxygen{
    font-family: 'Oxygen', sans-serif;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction:column;
}